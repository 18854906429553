import MissingToken from "../public/images/missing-token.png";
import INVALID_TOKEN_TRAITS from "./INVALID_TOKEN_TRAITS";

const INVALID_TOKEN_DATA: Token = {
    isInvalid: true,
    name: "Invalid Token",
    imageUrl: MissingToken.src,
    tokenStringId: "777",
    tokenId: 777,
    rank: 0,
    currentPrice: null,
    priceProjection: 777,
    deviation: null,
    marketplace: 0,
    confidence: 100,
    traits: INVALID_TOKEN_TRAITS,
    vibes: [],
};

export default INVALID_TOKEN_DATA;
