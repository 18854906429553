import React, { useRef, useEffect } from "react";
import { Box, Icon } from "@chakra-ui/react";
import { FaSlidersH } from "react-icons/fa";
import useCollectionTokensStore from "@store/collectionTokensStore";

const CollectionFilterSidebar = ({
    tabLabel,
    minHeight = "100%",
    maxWidth = "260px",
    onTabClick,
    children,
}: CollectionFilterSidebarProps): JSX.Element => {
    const scrollRef = useRef<HTMLDivElement>();
    const loading = useCollectionTokensStore((state) => state.loading);

    useEffect(() => {
        if (loading && scrollRef.current) scrollRef.current.scrollTo(0, 0);
    }, [loading]);

    return (
        <Box
            position="relative"
            layerStyle="slidingPanel"
            w="100%"
            maxW={maxWidth}
            h="100%"
            pointerEvents="auto"
            pr="5px"
        >
            <Box
                h="100%"
                ref={scrollRef}
                minH={minHeight}
                position="relative"
                zIndex="2"
                overflowY={loading ? "hidden" : "auto"}
                pr="10px"
            >
                {children}
            </Box>
            <Box
                layerStyle="slidingPanelTab"
                position="absolute"
                zIndex="1"
                style={{ writingMode: "vertical-rl" }}
                onClick={onTabClick}
                boxShadow="4px 4px 8px rgba(0, 0, 0, 0.25)"
            >
                {tabLabel}{" "}
                <Icon
                    as={FaSlidersH}
                    transform="rotate(90deg)"
                    w="12px"
                    h="12px"
                    ml="2px"
                    mt="4px"
                    color="#979797"
                />
            </Box>
        </Box>
    );
};

export default CollectionFilterSidebar;
