import { useSessionStore, setQuickTourIsOpen } from "@store/sessionStore";
import { Flex, Icon } from "@chakra-ui/react";
import { useState } from "react";
import Tooltip from "@components/ui/Tooltip";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { GraduationIcon } from "@components/icons";

export const QuickTourButton = () => {
    const quickTourIsOpen = useSessionStore((state) => state.quickTourIsOpen);
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const [isHovering, setHovering] = useState(false);

    const handleOnClick = () => {
        setQuickTourIsOpen(true);
    };

    return (
        <>
            <Tooltip label="Quick Tour" isDisabled={!isDesktop}>
                <Flex
                    h="100%"
                    // mt="-3px"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="150%"
                    lineHeight="100%"
                    color="white"
                    filter={`drop-shadow(0px 0px ${
                        isHovering || quickTourIsOpen ? "4px #fff5" : "2px #0009"
                    })`}
                    _hover={{ cursor: "pointer" }}
                    onMouseEnter={() => {
                        if (isDesktop) setHovering(true);
                    }}
                    onMouseLeave={() => {
                        if (isDesktop) setHovering(false);
                    }}
                    onClick={handleOnClick}
                >
                    {isHovering || quickTourIsOpen ? (
                        <Icon as={GraduationIcon} color="gold.500" />
                    ) : (
                        <Icon as={GraduationIcon} color="lavenderGray" />
                    )}
                </Flex>
            </Tooltip>
        </>
    );
};

export default QuickTourButton;
