import { useEffect, useLayoutEffect, useRef } from "react";
import { loadCollectionData, setCollectionId } from "@store/collectionStore";
import {
    loadCollectionTokensData,
    loadUserCollectionTokens,
    clearUserOwnedTokens,
} from "@store/collectionTokensStore";
import useUser from "./useUser";

const useCollection = (collectionId: string) => {
    const lastCollectionId = useRef<string>();
    const { user } = useUser();

    useEffect(() => {
        if (!user) {
            clearUserOwnedTokens();
        }
    }, [user]);

    useLayoutEffect(() => {
        const fetchCollectionData = async () => {
            loadCollectionData(collectionId);
            loadCollectionTokensData(collectionId);
        };

        if (collectionId !== lastCollectionId.current) {
            lastCollectionId.current = collectionId;

            setCollectionId(collectionId);
            fetchCollectionData();
        }
    }, [collectionId]);

    return null;
};

export default useCollection;
