import React, { ReactElement, useEffect, useState } from "react";
import { AspectRatio, Box, Text } from "@chakra-ui/react";
import Link from "next/link";
import Error404 from "/public/images/errors/404.png";
import BRLogo from "/public/ui/br-logo.svg";
import Image from "next/image";
import { getCookie, hasCookie, deleteCookie } from "cookies-next";

type Error404PageProps = {};

const Error404Page = ({}: Error404PageProps): JSX.Element => {
    const [isMounted, setIsMounted] = useState<boolean>(false);

    useEffect(() => {
        if (!isMounted) setIsMounted(true);
    }, []);

    if (!isMounted) return null;

    let errorMessage = "Looks like a wrong URL?";

    // Next doesn't have a way to pass extra props to the 404 page, so there's no way
    // of showing different error messages for this page.
    // A simple hack (https://github.com/vercel/next.js/discussions/28953) was proposed:
    // Store a value in a short-lived cookie (~20s) and read that vale on the Error404Page
    // component to set a custom error message. And delete the cookie value afterwards.
    if (hasCookie("not-found")) {
        // errorMessage = getCookie("not-found") as string;
        deleteCookie("not-found");
    }

    const { width, height } = Error404;

    return (
        <Box
            w="100%"
            minH="100vh"
            position="relative"
            justifyContent="center"
            py={{ base: 0, sm2: 12, md: 16 }}
            display={{ base: "flex", sm2: "block" }}
            flexDirection="column"
        >
            <AspectRatio w="80%" maxW="860px" mx="auto" ratio={width / height}>
                <Image src={Error404} layout="fill" alt="Page not found." />
            </AspectRatio>
            <Box
                w="90%"
                maxW="860px"
                mx="auto"
                textAlign="center"
                textStyle="site.text"
                mt={{ base: 8, md: 16 }}
            >
                <Text>{errorMessage}</Text>
                <Text>Let&apos;s get you back on the right path.</Text>
                <Link href="/" passHref>
                    <a>
                        <AspectRatio
                            w={{ base: "80px", md: "120px" }}
                            mx="auto"
                            mt={6}
                            ratio={65 / 52}
                            cursor="pointer"
                        >
                            <Image src={BRLogo} layout="fill" alt="Beyond Rarity" />
                        </AspectRatio>
                    </a>
                </Link>
            </Box>
        </Box>
    );
};

Error404Page.getLayout = function getLayout(page: ReactElement) {
    return <>{page}</>;
};

export default Error404Page;
