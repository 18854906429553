import useCollection from "@hooks/useCollection";
import useCollectionUpdates from "@hooks/useCollectionsUpdates";

type CollectionManagerProps = {
    collectionId: string;
};

export const CollectionManager = ({ collectionId }: CollectionManagerProps) => {
    // Loads collection data, tokens and current user owned tokens for the collection
    useCollection(collectionId);

    // Also starts the SWR hook to fetch updates for the current collection
    useCollectionUpdates(collectionId);

    return null;
};

export default CollectionManager;
