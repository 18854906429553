import React from "react";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { VStack } from "@chakra-ui/react";
import { TokenListSortBar, TokenList } from "@components/ui";

const CollectionTokensSidebar = ({ ...props }): JSX.Element => {
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });

    return (
        <VStack
            w="100%"
            gap={2}
            px={isDesktop ? "base" : "0px"}
            h="100%"
            position="relative"
            {...props}
        >
            {isDesktop ? <TokenListSortBar /> : null}
            <TokenList />
        </VStack>
    );
};

export default CollectionTokensSidebar;
