import dynamic from "next/dynamic";

const QuickTourDynamic = dynamic(
    () => import("@components/quickTour/QuickTour").then((mod) => mod.QuickTour),
    {
        ssr: false,
    },
);

export default QuickTourDynamic;
