import React, { ReactElement } from "react";
import { AspectRatio, Box, Text } from "@chakra-ui/react";
import Link from "next/link";
import Error500 from "/public/images/errors/500.png";
import BRLogo from "/public/ui/br-logo.svg";
import Image from "next/image";

type Error500PageProps = {};

const Error500Page = ({}: Error500PageProps): JSX.Element => {
    const { width, height } = Error500;

    return (
        <Box
            w="100%"
            minH="100vh"
            position="relative"
            justifyContent="center"
            py={{ base: 0, sm2: 12, md: 16 }}
            display={{ base: "flex", sm2: "block" }}
            flexDirection="column"
        >
            <AspectRatio w="80%" maxW="860px" mx="auto" ratio={width / height}>
                <Image src={Error500} layout="fill" alt="Something went wrong." />
            </AspectRatio>
            <Box
                w="90%"
                maxW="860px"
                mx="auto"
                textAlign="center"
                textStyle="site.text"
                mt={{ base: 8, md: 16 }}
            >
                <Text>Well, this is unexpected...</Text>
                <Text>Let&apos;s get you back on the right path.</Text>
                <Link href="/" passHref>
                    <a>
                        <AspectRatio
                            w={{ base: "80px", md: "120px" }}
                            mx="auto"
                            mt={6}
                            ratio={65 / 52}
                            cursor="pointer"
                        >
                            <Image src={BRLogo} layout="fill" alt="Beyond Rarity" />
                        </AspectRatio>
                    </a>
                </Link>
            </Box>
        </Box>
    );
};

Error500Page.getLayout = function getLayout(page: ReactElement) {
    return <>{page}</>;
};

export default Error500Page;
