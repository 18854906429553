import { API } from "@config/app";
import collectionStore from "@store/collectionStore";
import { useLayoutEffect } from "react";
import { getPathInfo } from "@utils";
import useSWR from "swr";
import useCollectionTokensStore, { updateCollectionTokensData } from "@store/collectionTokensStore";
import LastListingsUpdate from "@components/breadcrumbs/LastListingsUpdate";

const fetcher = ({ url, collectionId }) => {
    // The first call to /updates endpoint for any collection should be with empty timestamps.
    // Both `timestampListings` and `timestampBriteline` query params should be empty, this way
    // the API knows that we're asking for the last update timestamps.
    // Once we have these timestamps we can keep querying the /updates endpoints using these
    // values.
    const { listingsLastUpdate, britelineLastUpdate } = collectionStore.getState();
    const collectionTokensMap = useCollectionTokensStore.getState().tokensMap;

    if (!listingsLastUpdate || !britelineLastUpdate) {
        console.log(
            `No timestamps info for "${collectionId}", skipping fetching updates until collection info is ready.`,
        );
        return null;
    }

    const endpoint = `${url}?timestampListings=${listingsLastUpdate}&timestampBriteline=${britelineLastUpdate}`;

    // console.log(`Fetching updates for ${collectionId}...`);
    return fetch(endpoint).then((res) => {
        // console.log(`Updates for ${collectionId} loaded.`);
        return res.json();
    });
};

/**
 *  Starts fetching for collection data updates (listings & briteline) using a SWR hook.
 *  This hooks waits for `listingsLastUpdate` and `britelineLastUpdate` values to be
 *  available in the collectionStore. The initial values for these variables comes from
 *  the /info endpoint for the current collection.
 *  Once the info for a collection is loaded, these values are set and the SWR hook
 *  starts fetching every 10 seconds.
 */
const useCollectionUpdates = (collectionId: string, dataLastUpdate?: string) => {
    const { isStage } = getPathInfo();

    const API_PATH = `${API.DOMAIN}${isStage ? "/stage" : ""}${API.PATH}`;
    const API_ENDPOINT = `${API_PATH}/collections/${collectionId}/updates`;

    const { data, error } = useSWR({ url: API_ENDPOINT, collectionId }, fetcher, {
        refreshInterval: (data) => {
            return !!data ? 10000 : 1000;
        },
        revalidateIfStale: true,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
    });

    // Updates the data last update timestamp, if changed from previous fetch
    if (!!data?.dataLastUpdate && data.dataLastUpdate !== dataLastUpdate) {
        collectionStore.setState((state) => ({
            dataLastUpdate: data.dataLastUpdate,
        }));
    }

    if (data?.listings?.data?.length || data?.tokens?.data?.length) {
        console.log("Need to update some tokens data...");
        const tokensUpdated = updateCollectionTokensData(data.listings, data.tokens);

        if (tokensUpdated) {
            console.log("Token data updated successfully!");
            const lastListingsTimestamp = data?.listings?.timestamp;
            const lastBritelineTimestamp = data?.tokens?.timestamp;

            // store last listings update timestamp if changed
            if (
                lastListingsTimestamp &&
                lastListingsTimestamp !== collectionStore.getState().listingsLastUpdate
            ) {
                collectionStore.setState((state) => ({
                    listingsLastUpdate: lastListingsTimestamp,
                }));
            }

            // store last briteline update timestamp if changed
            if (
                lastBritelineTimestamp &&
                lastBritelineTimestamp !== collectionStore.getState().britelineLastUpdate
            ) {
                collectionStore.setState((state) => ({
                    britelineLastUpdate: lastBritelineTimestamp,
                }));
            }
        } else {
            console.log("No changes found in tokens data.");
        }
    }

    return null;
};

export default useCollectionUpdates;
