import React from "react";
import Head from "next/head";
import useCollectionStore from "@store/collectionStore";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { CollectionSocialShare, TokenSocialShare } from "@components/socialshare/SocialShare";

type CollectionHTMLHeadProps = {
    tokenData: Token;
    collectionSocialData: CollectionSocialShareData;
};

const CollectionHTMLHead = ({
    tokenData,
    collectionSocialData,
}: CollectionHTMLHeadProps): JSX.Element => {
    const collection = useCollectionStore((state) => state.collection);
    const token = useCollectionTokensStore((state) => state.selectedToken) || tokenData;

    const {
        collectionId,
        name,
        description: collectionDescription,
        itemsAmount,
    } = collection || collectionSocialData;

    if (token) {
        return (
            <TokenSocialShare
                collectionId={collectionId}
                collectionName={name}
                collectionSize={itemsAmount}
                tokenStringId={token.tokenStringId}
                tokenName={token.name}
                tokenRank={token.rank}
                image={token.imageUrl || token.animationUrl}
            />
        );
    }

    return (
        <CollectionSocialShare
            collectionId={collectionId}
            collectionName={name}
            description={collectionDescription}
        />
    );
};

export default CollectionHTMLHead;
