import React, { useEffect, useRef } from "react";
import { HStack, Flex, Text, Box, Collapse, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Separator, CircleIconButton, SkeletonBox } from "@components/ui";
import HeaderDataItem from "../HeaderDataItem";
import NoBriteLineInfo from "@components/ui/NoBriteLineInfo";
import { FaDiscord, FaTwitter, FaEthereum } from "react-icons/fa";
import { OpenSeaIcon, WWWIcon } from "../icons";
import { compactDecimalsNumber } from "@utils/helpers";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import useCollectionStore from "@store/collectionStore";
import { useSwrCollectionStats } from "@hooks/swr";

const CollectionDataHeader = ({
    collectionId,
    name,
    itemsAmount = 0,
    ownersAmount = 0,
    floorPrice = 0,
    volume = 0,
    openseaURL,
    websiteURL,
    discordURL,
    twitterURL,
}: CollectionDataHeaderProps): JSX.Element => {
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });
    const { isOpen, onToggle } = useDisclosure();
    const collectionLoading = useCollectionStore((state) => state.loading);
    const {
        data: statsData,
        error: statsError,
        isValidating: statsIsValidating,
    } = useSwrCollectionStats(collectionId, 1000 * 20);

    const showMoreBtn = useRef<HTMLParagraphElement>();

    const circleSize: string = !isDesktop ? "22px" : "26px";
    const circleRadius: number = !isDesktop ? 11 : 13;
    const separatorOrientation: "horizontal" | "vertical" = !isDesktop ? "horizontal" : "vertical";

    const loading = collectionLoading || !statsData || statsError;

    // console.debug("collectionLoading: ", collectionLoading);
    // console.debug("statsdata: ", statsData);
    // console.debug("statsError: ", statsError);
    // console.debug("loading: ", loading);

    const renderSocialIcons = (): JSX.Element =>
        !loading ? (
            <>
                {openseaURL && (
                    <CircleIconButton icon={OpenSeaIcon} href={openseaURL} size={circleSize} />
                )}
                {websiteURL && (
                    <CircleIconButton icon={WWWIcon} href={websiteURL} size={circleSize} />
                )}
                {discordURL && (
                    <CircleIconButton icon={FaDiscord} href={discordURL} size={circleSize} />
                )}
                {twitterURL && (
                    <CircleIconButton icon={FaTwitter} href={twitterURL} size={circleSize} />
                )}
            </>
        ) : (
            <SkeletonBox
                h={!isDesktop ? "22px" : "26px"}
                w={!isDesktop ? "136px" : "152px"}
                bg="#464E6C"
                clipPath="url(#myClip)"
            >
                <svg width="0" height="0">
                    <defs>
                        <clipPath id="myClip">
                            <circle cx={circleRadius} cy={circleRadius} r={circleRadius} />
                            <circle cx={circleRadius * 3 + 16} cy={circleRadius} r={circleRadius} />
                            <circle
                                cx={circleRadius * 5 + 16 * 2}
                                cy={circleRadius}
                                r={circleRadius}
                            />
                            <circle
                                cx={circleRadius * 7 + 16 * 3}
                                cy={circleRadius}
                                r={circleRadius}
                            />
                        </clipPath>
                    </defs>
                </svg>
            </SkeletonBox>
        );

    return isDesktop ? (
        // Desktop Header
        <Flex layerStyle="header.container">
            <Box layerStyle="header.dataContainer">
                <Text textStyle="collectionHeader.name" noOfLines={1} display="block">
                    {name}
                </Text>
                <NoBriteLineInfo
                    placement="right"
                    textAlign="left"
                    width="220px"
                    iconProps={{ h: "20px", w: "20px" }}
                />
            </Box>
            <Separator orientation={separatorOrientation} />
            <HStack gap={1} flex="0 1 auto" h="27px">
                <HeaderDataItem
                    label="items"
                    value={compactDecimalsNumber(itemsAmount)}
                    loading={loading}
                />
                <HeaderDataItem
                    label="owners"
                    value={
                        statsData?.owners != null ? compactDecimalsNumber(statsData?.owners) : null
                    }
                    loading={loading}
                />
                <HeaderDataItem
                    label="floor"
                    value={
                        statsData?.floor != null ? compactDecimalsNumber(statsData?.floor, 3) : null
                    }
                    decimals={2}
                    icon={FaEthereum}
                    loading={loading}
                />
                <HeaderDataItem
                    label="volume"
                    value={
                        statsData?.volumeAllTime != null
                            ? compactDecimalsNumber(statsData?.volumeAllTime)
                            : null
                    }
                    icon={FaEthereum}
                    loading={loading}
                />
            </HStack>

            <Separator orientation={separatorOrientation} />
            <Flex flexDirection="row" gap={4} flex="0 1 auto">
                {renderSocialIcons()}
            </Flex>
        </Flex>
    ) : (
        // Mobile header
        <Flex
            layerStyle="header.container"
            flexDirection="column"
            px="10px"
            position="relative"
            gap="4px"
        >
            <Box layerStyle="header.dataContainer" width="100%" justifyContent="space-between">
                <Text textStyle="collectionHeader.name" noOfLines={1} as="div" display="block">
                    {name}
                </Text>
                <NoBriteLineInfo />
                <Text
                    ref={showMoreBtn}
                    textStyle="traitOption"
                    onClick={onToggle}
                    whiteSpace="nowrap"
                    ml="5px"
                >
                    Show more details{" "}
                    <ChevronDownIcon
                        transition="transform .3s"
                        transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                    />
                </Text>
            </Box>
            <Collapse
                in={isOpen}
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                transition={{ enter: { duration: 0.15 }, exit: { duration: 0.15 } }}
            >
                <Separator orientation={separatorOrientation} />
                <HStack gap={1} flex="0 1 auto" justifyContent="center" py="3px" h="23px" w="100%">
                    <HeaderDataItem
                        label="items"
                        value={compactDecimalsNumber(itemsAmount)}
                        loading={loading}
                    />
                    <HeaderDataItem
                        label="owners"
                        value={statsData?.owners ? compactDecimalsNumber(statsData?.owners) : null}
                        loading={loading}
                    />
                    <HeaderDataItem
                        label="fl"
                        value={statsData?.floor ? compactDecimalsNumber(statsData?.floor, 3) : null}
                        decimals={2}
                        icon={FaEthereum}
                        loading={loading}
                    />
                    <HeaderDataItem
                        label="vol"
                        value={
                            statsData?.volumeAllTime
                                ? compactDecimalsNumber(statsData?.volumeAllTime)
                                : null
                        }
                        icon={FaEthereum}
                        loading={loading}
                    />
                </HStack>

                <Separator orientation={separatorOrientation} />
                <Flex flexDirection="row" gap={4} flex="0 1 auto" pt="6px" pb="1px">
                    {renderSocialIcons()}
                </Flex>
            </Collapse>
        </Flex>
    );
};

export default CollectionDataHeader;
