import React from "react";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { SkeletonBox } from "./ui";

const HeaderDataItem = ({ loading, icon, value, label }: HeaderDataItemProps): JSX.Element => {
    const readableValue = value == null ? "-" : value;

    return !loading ? (
        <Flex direction="row" alignItems="baseline" gap={{ base: "3px", lg: "5px" }}>
            {icon && (
                <Icon
                    as={icon}
                    height={{ base: "12px", lg: "18px" }}
                    width={{ base: "10px", lg: "18px" }}
                    alignSelf={{ base: "baseline", lg: "center" }}
                />
            )}
            <Text textStyle="h3">{readableValue}</Text>
            <Text fontWeight={300} fontSize="15px">
                {label}
            </Text>
        </Flex>
    ) : (
        <SkeletonBox w="75px" h="100%" maxW="25%" bg="#464E6C" />
    );
};

export default HeaderDataItem;
