import React, { CSSProperties } from "react";
import {
    Popover,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    PopoverAnchor,
    Circle,
    Icon,
    Portal,
    useDisclosure,
    PlacementWithLogical,
    IconProps,
} from "@chakra-ui/react";
import { AiFillExclamationCircle } from "react-icons/ai";
import useSessionStore from "@store/sessionStore";

type NoBriteLineInfoProps = {
    placement?: PlacementWithLogical;
    textAlign?: "center" | "left";
    width?: string;
    iconProps?: IconProps;
};

const NoBriteLineInfo = ({
    placement = "bottom",
    textAlign = "center",
    width = "240px",
    iconProps,
}: NoBriteLineInfoProps): JSX.Element => {
    const isConnected = useSessionStore((state) => state.connected);
    const { onOpen, onClose, onToggle, isOpen } = useDisclosure();

    if (isConnected === 0) {
        return (
            <Popover isOpen={isOpen} placement={placement}>
                <PopoverAnchor>
                    <Circle
                        size="18px"
                        ml="8px"
                        bg="rgba(0,0,0,0)"
                        cursor="pointer"
                        onMouseEnter={onOpen}
                        onMouseLeave={onClose}
                        onTouchStart={onToggle}
                    >
                        <Icon
                            as={AiFillExclamationCircle}
                            w="16px"
                            h="16px"
                            color="gold.500"
                            pointerEvents="none"
                            {...iconProps}
                        />
                    </Circle>
                </PopoverAnchor>

                <Portal>
                    <PopoverContent bgColor="blue.darker" color="#fff" w={width}>
                        <PopoverArrow bgColor="blue.darker" />
                        <PopoverBody fontSize="14px" textAlign={textAlign}>
                            BRITE values are being evaluated
                            <br /> for this collection.
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        );
    }
};

export default NoBriteLineInfo;
