import { ReactElement } from "react";
import Error404Page from "./404";
import Error500Page from "./500";

function Error({ statusCode }) {

    console.debug()
    if (statusCode == 404) return <Error404Page />;

    return <Error500Page />;
}

Error.getInitialProps = ({ res, err }) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return { statusCode };
};

Error.getLayout = function getLayout(page: ReactElement) {
    return <>{page}</>;
};

export default Error