import React from "react";
import Image from "next/image";
import { Box, Icon, VStack } from "@chakra-ui/react";
import { Header, Panel } from "@components/layout";
import useIsMounted from "@hooks/useIsMounted";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { TokenModal } from "@components/ui";
import CallingNFTBanner from "@components/banners/CallingNFTBanner";
import CollectionManager from "./CollectionManager";
import OwnedDiamondImage from "@components/images/OwnedDiamondImage";
import chakraTheme from "@theme";

type CollectionPageProps = {
    collectionId: string;
};

const CollectionPage = ({ collectionId }: CollectionPageProps): JSX.Element => {
    const isMounted = useIsMounted();
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });

    if (!isMounted()) {
        return <h1 style={{ padding: "25px" }}>Loading...</h1>;
    }

    return (
        <>
            <CollectionManager collectionId={collectionId} />
            <Box position="absolute" visibility="hidden" zIndex="-1">
                <Image
                    id="InactiveOverRangeImage"
                    src="/ui/OverRangeList_Inactive.svg"
                    alt="Beyond Rarity - Over Range"
                    width="12px"
                    height="12px"
                />
                <Image
                    id="ActiveOverRangeImage"
                    src="/ui/OverRangeList_Active.svg"
                    alt="Beyond Rarity - Over Range"
                    width="22px"
                    height="22px"
                />
                <Image
                    id="ActiveOwnedOverRangeImage"
                    src="/icons/diamond-circle.svg"
                    alt="Beyond Rarity - Over Range"
                    width="22px"
                    height="22px"
                />
                <Image
                    id="ActiveDisconnectedOverRangeImage"
                    src="/ui/OverRangeList_Active-Disconnected.svg"
                    alt="Beyond Rarity - Over Range"
                    width="22px"
                    height="22px"
                />
                <OwnedDiamondImage id="OwnedDiamond" height="14px" />
                <OwnedDiamondImage id="OwnedDiamondHover" height="26px" />
                <OwnedDiamondImage id="ListedOwnedDiamond" height="18px" />
                <OwnedDiamondImage id="ListedOwnedDiamondHover" height="26px" />
                <OwnedDiamondImage
                    id="OwnedOverangeImage"
                    height="14px"
                    borderColor={chakraTheme.colors.chart.datapoint.owned.point}
                    startFillColor={chakraTheme.colors.chart.datapoint.owned.over}
                    endFillColor={chakraTheme.colors.chart.datapoint.owned.over}
                />
            </Box>
            <CallingNFTBanner useReopen={false} />
            <VStack
                layerStyle="collectionPage"
                gap={isDesktop ? "8px" : "4px"}
                p={isDesktop ? "20px" : 0}
            >
                <Header />
                <Panel />
            </VStack>
            {/* REMOVE FOOTER AND IMPLEMENT IN AppLayout ONCE BETAGATE IS REMOVED */}
            {/* <Footer /> */}
            <TokenModal />
        </>
    );
};

export default CollectionPage;
