import React, { useRef } from "react";
import chakraTheme from "@theme";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { Grid, GridItem, Box, Flex, Accordion } from "@chakra-ui/react";
import { CollectionTokensSidebar, CollectionFilterSidebar } from "@components/layout";
import {
    TraitsFilterAccordion,
    MobileFiltersDrawer,
    BreadcrumbsList,
    AddTokenInput,
    SaleStatusFilter,
    RankFilters,
    PriceFilters,
    MinDeviationFilters,
    VibesFilters,
    SkeletonBox,
    FilterExpandable,
    MobileBreadcrumbs,
} from "@components/ui";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { useSessionStore } from "@store/sessionStore";
import shallow from "zustand/shallow";
import dynamic from "next/dynamic";
import MarketplaceFilters from "@components/filters/MarketplaceFilters";

const HotspotChartDynamic = dynamic(
    () => import("@components/charts").then((mod) => mod.HotspotChart),
    {
        ssr: false,
    },
);

const GRID_LG = {
    templateColumns: "5px 1fr 480px",
    templateRows: "auto 1fr",
    templateAreas: `
        "breadcrumbs breadcrumbs breadcrumbs"
        "chart chart list"
    `,
};

type PanelProps = {};

const VIBES_AND_TRAITS_SIDEBAR_WIDTH = 260;

const Panel = ({}: PanelProps): JSX.Element => {
    const { isDesktop, isNarrowPortrait } = useDebouncedWindowSize({
        useIsDesktop: true,
        useIsNarrowPortrait: true,
    });
    const isConnected = useSessionStore((state) => state.connected);
    const filtersSidebar = useRef<HTMLDivElement>();
    const filtersSidebarOpen = useRef<boolean>(true);
    const { startTokenId, endTokenId, loading } = useCollectionTokensStore(
        (state) => ({
            startTokenId: state.startTokenId,
            endTokenId: state.endTokenId,
            loading: state.loading,
        }),
        shallow,
    );

    const { templateColumns, templateRows, templateAreas } = GRID_LG;

    const toggleOpen = () => {
        filtersSidebarOpen.current = !filtersSidebarOpen.current;
        filtersSidebar.current.style.width = `${
            filtersSidebarOpen.current ? VIBES_AND_TRAITS_SIDEBAR_WIDTH : 0
        }px`;
        filtersSidebar.current.style.boxShadow = filtersSidebarOpen.current
            ? "4px 4px 8px rgba(0, 0, 0, 0.25)"
            : "none";
    };

    if (isDesktop) {
        return (
            <Grid
                layerStyle="mainGrid"
                templateColumns={templateColumns}
                templateRows={templateRows}
                templateAreas={templateAreas}
                rowGap="10px"
                columnGap="10px"
                flex="1 0 auto"
            >
                <GridItem area="breadcrumbs" layerStyle="breadcrumbs.container">
                    <BreadcrumbsList />
                </GridItem>

                <GridItem area="chart">
                    <Flex layerStyle="fluidChart.container">
                        <Box
                            ref={filtersSidebar}
                            layerStyle="fluidChart.slidingPanel"
                            w={VIBES_AND_TRAITS_SIDEBAR_WIDTH}
                            borderRightRadius="lg"
                            boxShadow="4px 4px 8px rgba(0, 0, 0, 0.25)"
                        >
                            <Box
                                layerStyle="fluidChart.innerPanel"
                                w={VIBES_AND_TRAITS_SIDEBAR_WIDTH}
                            >
                                <CollectionFilterSidebar
                                    tabLabel="FILTERS"
                                    maxHeight="100%"
                                    onTabClick={toggleOpen}
                                >
                                    <SaleStatusFilter loading={loading} />
                                    <AddTokenInput
                                        loading={loading}
                                        placeholder={`Add token ID (${startTokenId} - ${endTokenId})`}
                                        min={startTokenId}
                                        max={endTokenId}
                                    />

                                    {loading ? (
                                        <Flex flexDirection="column" gap={2} w="100%" mb={2}>
                                            <SkeletonBox h="38px" />
                                            <SkeletonBox h="38px" />
                                            <SkeletonBox h="38px" />
                                            <SkeletonBox h="38px" />
                                        </Flex>
                                    ) : (
                                        <Accordion allowMultiple rounded="lg" defaultIndex={[]}>
                                            <FilterExpandable label="Rarity Rank">
                                                <RankFilters />
                                            </FilterExpandable>
                                            <FilterExpandable label="Price">
                                                <PriceFilters />
                                            </FilterExpandable>
                                            <FilterExpandable
                                                label="Min Absolute Deviation"
                                                disabled={!isConnected}
                                            >
                                                <MinDeviationFilters />
                                            </FilterExpandable>
                                            <FilterExpandable label="Marketplace">
                                                <MarketplaceFilters />
                                            </FilterExpandable>
                                        </Accordion>
                                    )}
                                    <TraitsFilterAccordion label="Traits" loading={loading} />
                                    <VibesFilters label="Vibes" mt={8} />
                                </CollectionFilterSidebar>
                            </Box>
                        </Box>
                        <Flex layerStyle="chartContainer" minH={chakraTheme.breakpoints.sm}>
                            <HotspotChartDynamic />
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem area="list" maxW="100%" position="relative">
                    <CollectionTokensSidebar layerStyle="darkBluePanel" />
                </GridItem>
            </Grid>
        );
    }

    // Mobile View
    return (
        <>
            <Flex layerStyle="chart.mobile.container">
                <Flex
                    layerStyle="chart.mobile.hotspotChart"
                    minH={isNarrowPortrait ? "calc(100vw - 50px)" : chakraTheme.breakpoints.sm}
                >
                    <Box w="100%" maxW="200px" alignSelf="center" h="32px" position="relative">
                        <AddTokenInput
                            placeholder={`Add token ID (${startTokenId} - ${endTokenId})`}
                            min={startTokenId}
                            max={endTokenId}
                            loading={loading}
                        />
                    </Box>
                    <MobileBreadcrumbs defaultIsOpen={false} bgColor="unset" m={0} />
                    <HotspotChartDynamic />
                </Flex>
                <CollectionTokensSidebar />
            </Flex>

            <MobileFiltersDrawer />
        </>
    );
};

export default Panel;
