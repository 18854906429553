import React, { useEffect } from "react";
import { GetServerSideProps } from "next";
import { CollectionHTMLHead } from "@components/layout";
import { useRouter } from "next/router";
import { getCollectionSocialShareData, getTokenData } from "@apiSrc";
import { ParsedUrlQuery } from "querystring";
import CollectionPage from "@components/layout/CollectionPage";
import { setCollectionSocialData } from "@store/collectionStore";
import { setSelectedToken } from "@store/collectionTokensStore";
import INVALID_TOKEN_DATA from "@data/INVALID_TOKEN_DATA";
import Error from "@pages/_error";
import axios, { AxiosError } from "axios";
import BetaGate from "@components/betaGate/BetaGate";
import QuickTourDynamic from "@components/quickTour/QuickTourDynamic";
import { buildTokenName } from "@store/helpers";
import { getServerSession } from "next-auth";
import { createAuthOptions } from "@pages/api/auth/[...nextauth]";

type CollectionPageProps = {
    errorCode: number | string | undefined;
    collectionId: string;
    collectionSocialData: CollectionSocialShareData;
    tokenStringId?: TokenStringId;
    tokenData: Token;
};

type QueryProps = { collectionId: string; tokenId: string } & ParsedUrlQuery;

const CollectionIdPage = ({
    errorCode,
    collectionId,
    collectionSocialData,
    tokenData,
}: CollectionPageProps): JSX.Element => {
    const router = useRouter();
    const { collectionId: routerCollectionId, tokenId: routerTokenId } = router.query as QueryProps;

    useEffect(() => {
        if (collectionSocialData) setCollectionSocialData(collectionSocialData);

        // !! ------ COMMENT OUT THIS SECTION IF USING BETAGATE ------------- !!
        if (tokenData) {
            setSelectedToken(tokenData);
            // console.debug("token data: ", tokenData);
        } else if (routerTokenId) {
            // if url includes a token id but there's no token data the tokenId doesn't exist.
            // let's use the invalid token data.
            setSelectedToken(INVALID_TOKEN_DATA);
        }
        // --------- END SECTION TO COMMENT IF USING BETAGATE---------------------------------
    }, []);

    if (errorCode) return <Error statusCode={404} />;

    return (
        <>
            <CollectionHTMLHead collectionSocialData={collectionSocialData} tokenData={tokenData} />
            {/* <BetaGate tokenData={tokenData}> */}
            <QuickTourDynamic />
            <CollectionPage collectionId={routerCollectionId || collectionId} />
            {/* </BetaGate> */}
        </>
    );
};

export const getServerSideProps: GetServerSideProps = async ({ res, req, params, query }) => {
    const { collectionId } = params;
    const { tokenId } = query;

    try {
        const subdomain = req.headers?.host.split(/\./)[0];
        const isStage = subdomain.toLowerCase().endsWith("stage");
        const [collectionSocialData, tokenData] = await Promise.all([
            await getCollectionSocialShareData(collectionId as string, isStage),
            await getTokenData(collectionId as string, tokenId as string, isStage),
        ]);

        if (!collectionSocialData)
            return {
                notFound: true,
            };

        if (tokenData) {
            tokenData.name = buildTokenName(
                tokenData.name,
                collectionSocialData.name,
                tokenData.tokenStringId,
            );
        }

        return {
            props: {
                collectionId,
                collectionSocialData,
                tokenId: tokenId || null,
                tokenData,
                session: await getServerSession(req, res, createAuthOptions()),
            },
        };
    } catch (e: any | AxiosError) {
        let errorCode = e.response?.status || 500;

        if (axios.isAxiosError(e)) {
            console.debug();
            console.debug();
            console.debug("---------------------------");
            console.debug("AXIOS ERROR");
            console.debug("---------------------------");
            console.debug();
            console.debug(e);
        } else {
            console.debug();
            console.debug();
            console.debug("---------------------------");
            console.debug("NON-AXIOS ERROR");
            console.debug("---------------------------");
            console.debug();
            console.debug(e);
        }

        return {
            props: { errorCode },
        };
    }
};

export default CollectionIdPage;
