import {
    AspectRatio,
    Box,
    Center,
    Flex,
    Grid,
    GridItem,
    Icon,
    Image,
    Input,
    InputGroup,
    InputRightAddon,
    InputRightElement,
    Skeleton,
    Spacer,
    Spinner,
    Text,
} from "@chakra-ui/react";
import chakraTheme from "@theme";
import PUR from "@components/PUR";
import { InfoTooltip } from "@components/ui/InfoTooltip";
import { useSwrAllCollections } from "@hooks/swr";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { switchCollection } from "@store/collectionStore";
import { splitFeaturedCollections } from "@utils/helpers";
import { useRouter } from "next/router";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { MdClear, MdOutlineImageNotSupported, MdSearch } from "react-icons/md";
import { VariableSizeList as List } from "react-window";
import "@utils/number.extensions";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { isAndroid, isIOS } from "react-device-detect";
import { IoMdReturnLeft } from "react-icons/io";
import { FaEthereum } from "react-icons/fa";
import NextLink from "next/link";

const MIN_CONTRACT_SEARCH_LENGTH = 4;

const ComboboxInput = ({
    isLoading,
    isOpen,
    onChange,
    onFocus,
    onBlur,
    onClear,
    placeholder,
    selectedItem,
    listRef,
    filteredItems,
    fullList,
}) => {
    const input = useRef<HTMLInputElement>();
    const [localValue, setLocalValue] = useState("");

    useEffect(() => {
        setLocalValue("");
    }, [selectedItem]);

    useEffect(() => {
        if (selectedItem) {
            const itemIndex = fullList?.indexOf(selectedItem);

            if (itemIndex >= 0) {
                listRef.current?.scrollToItem(itemIndex, "center");
            }
        }
    }, [filteredItems, fullList]);

    useEffect(() => {
        if (isOpen) {
            if (input.current) input.current.focus();
        }
    }, [isOpen]);

    const onInputChange = (e) => {
        const { value } = e.target;
        setLocalValue(value);
        onChange?.(value.toLowerCase());
    };

    const onInputClear = (e) => {
        setLocalValue("");
        onClear?.();
        input.current?.focus();
    };

    // blurs on ESC key, good UX practice
    const onInputKeyDown = (e) => {
        if (e.keyCode === 27) {
            (document.activeElement as HTMLElement).blur();
        }
    };

    const onInputBlur = (e) => {
        onBlur?.();
    };

    return (
        <Skeleton w="100%" isLoaded={!isLoading} rounded={32} px={{ base: 2, lg: 0 }}>
            <Box
                w="100%"
                bg="linear-gradient(90deg, #F77A59 0%, #EA3A65 100%) 0% 0% no-repeat padding-box"
                rounded={32}
                p="2px"
            >
                <InputGroup w="100%" position="relative">
                    <Input
                        type="text"
                        ref={input}
                        h="100%"
                        minHeight="32px"
                        value={localValue}
                        {...chakraTheme.textStyles.site.forms.value}
                        rounded={32}
                        px={{ base: 4, lg: 7 }}
                        // py={7}
                        placeholder={placeholder}
                        // fontSize={{ base: 18, md: 24 }}
                        _placeholder={{
                            ...chakraTheme.textStyles.site.forms.placeholder,
                            color: "lavenderGray",
                        }}
                        _focus={{ border: "none", outline: "none", boxShadow: "none" }}
                        bg="panel.bg.dark"
                        border="none"
                        onChange={onInputChange}
                        onFocus={onFocus}
                        onBlur={onInputBlur}
                        onKeyDown={onInputKeyDown}
                        isDisabled={isLoading}
                        scrollMarginTop="20px"
                    />
                    <InputRightAddon
                        cursor="pointer"
                        fontSize={{ base: "14px", md: "18px" }}
                        bg="panel.bg.dark"
                        border="none"
                        borderRightRadius={32}
                        pr={{ base: 4, lg: 4 }}
                        // py={7}
                    >
                        {!localValue && <MdSearch />}
                        {localValue && (
                            <MdClear
                                onClick={onInputClear}
                                onMouseDown={(e) => e.preventDefault()}
                            />
                        )}
                    </InputRightAddon>
                </InputGroup>
            </Box>
        </Skeleton>
    );
};

export default function CollectionSearchBar({ fallbackCollections = null }) {
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const interval = useRef<ReturnType<typeof setInterval>>();
    const isMounted = useRef<boolean>(false);
    const searchBar = useRef<HTMLDivElement>();
    const lastScrollY = useRef<number>(0);
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const windowWidth = useWindowSiteStore((state) => state.windowWidth);
    const windowHeight = useWindowSiteStore((state) => state.windowHeight);
    const [viewportHeight, setViewportHeight] = useState<number>();
    const router = useRouter();
    const { collectionId: currentCollectionId } = router.query;
    const { data: swrData, error: swrError } = useSwrAllCollections(fallbackCollections);
    const maxPUR = swrData?.maxPUR;
    const collections = swrData?.collections;
    const searchRegEx = useRef<any>();
    const listRef = useRef<any>();
    const sizeMap = useRef<any>({});
    const [isOpen, setIsOpen] = useState<boolean>();
    const [search, setSearch] = useState("");
    const [featuredCollections, setFeaturedCollections] = useState<CollectionBaseData[]>([]);
    const [standardCollections, setStandardCollections] = useState<CollectionBaseData[]>([]);
    const [filteredItems, setFilteredItems] = useState<any[]>(collections);
    const [selectedItem, setSelectedItem] = useState(
        collections?.find((c) => c.collectionId === currentCollectionId),
    );

    const featuredList = featuredCollections.filter((collection, index) => {
        if (collection) {
            return filteredItems.some((item, index) => {
                return item.collectionId == collection.collectionId;
            });
        }
    });
    const standardList = standardCollections.filter((collection, index) => {
        return filteredItems.some((item, index) => {
            return item.collectionId == collection.collectionId;
        });
    });

    const LIST_PADDING_Y = isDesktop ? 6 : 2;

    let fullList = [];
    if (featuredList.length > 0) {
        fullList.push({ type: "heading", name: "Featured" });
        fullList.push(...featuredList);
    }

    if (featuredList.length > 0 && standardList.length > 0) {
        fullList.push({ type: "divider" });
    }

    fullList.push(...standardList);

    // console.debug("all filtered: ", filteredItems);
    // console.debug("featured: ", featuredList);
    // console.debug("standard: ", standardList);
    // console.debug("full: ", fullList);

    useEffect(() => {
        // Android only!! If Search Input is focused (isOpen === true) then adjust
        // scroll position on window resize (usually when the Keyobard shows up)
        // so the search field stays on top margin, leaving some room for the list.
        if (!isAndroid) return;
        if (searchBar.current && isOpen) {
            const { y } = searchBar.current.getBoundingClientRect();
            window?.scrollTo(0, y + window.scrollY - 10);
        }
    }, [windowHeight]);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            setViewportHeight(window.visualViewport.height);
        }

        return () => {
            clearTimeout(timeout.current);
            clearInterval(interval.current);
        };
    }, []);

    useEffect(() => {
        const { featured, standard } = splitFeaturedCollections(collections);

        setFilteredItems(collections);
        setFeaturedCollections(featured);
        setStandardCollections(standard);
        setSelectedItem(collections?.find((c) => c.collectionId === currentCollectionId));
    }, [collections, currentCollectionId]);

    useEffect(() => {
        if (selectedItem) {
            onSearchInputChange("");
        }
    }, [selectedItem]);

    useEffect(() => {
        if (isOpen && selectedItem) {
            const itemIndex = fullList.indexOf(selectedItem);
            if (itemIndex >= 0) {
                if (listRef.current) listRef.current?.scrollToItem(itemIndex, "center");
            }
        }
    }, [isOpen]);

    const onSearchInputChange = (value) => {
        searchRegEx.current = new RegExp(`${value}`, "g");

        const filteredCollections =
            value == ""
                ? collections
                : collections.filter((collection) => {
                      const { name, slug, contract } = collection;
                      return (
                          name.toLowerCase().includes(value) ||
                          slug.toLowerCase().includes(value) ||
                          (value.length >= MIN_CONTRACT_SEARCH_LENGTH &&
                              contract.toLowerCase().includes(value))
                      );
                  });

        // console.debug(filteredCollections);

        setSearch(value);
        setFilteredItems(filteredCollections);
    };

    const onSearchInputClear = (e) => {
        searchRegEx.current = new RegExp(``, "g");
        setSearch("");
        setFilteredItems(collections);
    };

    const onSearchInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(true);

        if (isIOS) {
            lastScrollY.current = window.scrollY;

            // Moves the searchbar to the top of the page
            timeout.current = setTimeout(() => {
                const { y } = searchBar.current.getBoundingClientRect();
                window?.scrollTo(0, y + window.scrollY - 10);
                document.body.scrollTop = y + window.scrollY - 10;
            }, 100);

            // We need to check the new viewport height to adjust the scrollable list size
            // when the keyboard is open. Safari never fires an event when the keyboard
            // toggles visibility, so we need to check the viewport height inside an interval.
            // 100ms delay seems reliable.
            interval.current = setInterval(() => {
                if (window.visualViewport.height !== window.innerHeight) {
                    clearInterval(interval.current);
                    setViewportHeight(window.visualViewport.height);
                }
            }, 100);
        }
    };

    const onSearchInputBlur = (e) => {
        setIsOpen(false);

        if (isIOS) {
            setTimeout(() => {
                window?.scrollTo(0, lastScrollY.current);
                document.body.scrollTop = lastScrollY.current;
            }, 100);
        }
    };

    const setSize = useCallback((index, size) => {
        listRef.current.resetAfterIndex(0);
        sizeMap.current = { ...sizeMap.current, [`item${index}`]: size };
    }, []);

    const getItemSize = useCallback((index) => {
        return sizeMap.current[`item${index}`] || 94;
    }, []);

    const NoResultsItem = () => {
        return (
            <Center p={4}>
                <Box>No Collections found that match your search.</Box>
            </Center>
        );
    };

    const LoadingItem = () => {
        return (
            <Center p={4}>
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="steelblue.500"
                    w="25px"
                    h="25px"
                />
            </Center>
        );
    };

    // if (!swrData || swrData?.length == 0 || swrError) return;
    const isLoading = !swrData;

    const CollectionItem = ({ index, style }: any) => {
        const itemRef = useRef<any>();
        const item = fullList[index];
        const isActive = item === selectedItem;
        const [isWideLayout, setIsWideLayout] = useState(
            searchBar?.current?.scrollWidth ? searchBar?.current?.scrollWidth >= 700 : false,
        );
        // console.debug("fulllist[%s]: %s", index, fullList[index]);
        // console.debug("filteredItems[%s]: %s", index, filteredItems[index]);
        // console.debug("item: ", item)
        // if (!item) {
        //     console.debug("index: ", index);
        //     console.debug("fullList: ", fullList);
        // }

        useEffect(() => {
            if (itemRef.current) setSize(index, itemRef.current.getBoundingClientRect().height);
        }, []);

        const preventDefault = (e) => {
            e.preventDefault();
        };

        const onItemClick = (e) => {
            setIsOpen(false);
            (document.activeElement as HTMLElement).blur();
            // router.push(`/c/${item?.collectionId}`, `/c/${item?.collectionId}`, { shallow: true });
            // switchCollection(item?.collectionId, item?.name, item?.contract, item?.description);
        };

        const ExtraInfo = () => {
            return (
                <>
                    <Box
                        fontSize={isDesktop ? undefined : "75%"}
                        onMouseDown={preventDefault}
                        onClick={preventDefault}
                    >
                        <Box>
                            <Text as="span">Contract: </Text>
                            {!search || search.length < MIN_CONTRACT_SEARCH_LENGTH ? (
                                item?.contract
                            ) : (
                                <Highlighter
                                    textToHighlight={item ? item.contract : ""}
                                    searchWords={[searchRegEx.current]}
                                    highlightTag={({ children, highlightIndex }) => (
                                        <Box as="span" color="sorbet" fontWeight={600}>
                                            {children}
                                        </Box>
                                    )}
                                />
                            )}
                        </Box>
                        <Box>
                            <Text as="span">Collection ID: </Text>
                            <Highlighter
                                textToHighlight={`${item?.slug || ""}`}
                                searchWords={[searchRegEx.current]}
                                highlightTag={({ children, highlightIndex }) => (
                                    <Box as="span" color="sorbet" fontWeight={600}>
                                        {children}
                                    </Box>
                                )}
                            />
                        </Box>
                    </Box>
                </>
            );
        };

        const CollectionName = () => {
            return (
                <>
                    <Flex
                        direction="row"
                        h="100%"
                        w="100%"
                        textStyle="site.siteText"
                        justify={isWideLayout ? "start" : "start"}
                        align={isWideLayout ? "center" : "start"}
                    >
                        <Box
                            w="100%"
                            noOfLines={isWideLayout ? 2 : 1}
                            overflow="hidden"
                            fontSize="110%"
                        >
                            <Text noOfLines={isWideLayout ? 2 : 1} lineHeight="105%">
                                <Highlighter
                                    textToHighlight={item ? item.name : ""}
                                    searchWords={[searchRegEx.current]}
                                    highlightTag={({ children }) => (
                                        <>
                                            <Box as="span" color="sorbet" fontWeight={600}>
                                                {children}
                                            </Box>
                                        </>
                                    )}
                                />
                            </Text>
                        </Box>
                        {/* {!isWideLayout && (
                            <>
                                <InfoTooltip
                                    label={<ExtraInfo />}
                                    iconSize={isWideLayout ? "20px" : "16px"}
                                    iconProps={{ my: 0 }}
                                />
                            </>
                        )} */}
                    </Flex>
                </>
            );
        };

        const Volume = () => {
            return (
                <>
                    <Flex
                        direction="row"
                        h="100%"
                        w="100%"
                        whiteSpace="nowrap"
                        textStyle="site.text"
                        fontWeight={300}
                        justify={isWideLayout ? "end" : "start"}
                        align="center"
                    >
                        <Icon as={FaEthereum} color="lavenderGray" my="0" />
                        <Text>
                            {item?.stats?.volume1Day == null
                                ? "--"
                                : (item?.stats?.volume1Day as Number).abbreviate(0, 0, 1)}
                            &nbsp;
                        </Text>
                        <Text as="span" color="lavenderGray">
                            vol (1d)
                        </Text>
                    </Flex>
                </>
            );
        };

        const Floor = () => {
            return (
                <>
                    <Flex
                        direction="row"
                        h="100%"
                        w="100%"
                        whiteSpace="nowrap"
                        textStyle="site.text"
                        fontWeight={300}
                        justify={isWideLayout ? "end" : "end"}
                        align="center"
                    >
                        <Icon as={FaEthereum} color="lavenderGray" my="0" />
                        <Text>
                            {item?.stats?.floor == null
                                ? "--"
                                : (item?.stats?.floor as Number).abbreviate(0, 0, 2)}
                            &nbsp;
                        </Text>
                        <Text as="span" color="lavenderGray">
                            fl
                        </Text>
                    </Flex>
                </>
            );
        };

        const NumberOfItems = () => {
            return (
                <>
                    <Flex
                        direction="row"
                        h="100%"
                        w="100%"
                        whiteSpace="nowrap"
                        textStyle="site.text"
                        fontWeight={300}
                        justify={isWideLayout ? "end" : "end"}
                        align="center"
                        pl={isWideLayout ? 0 : 2}
                    >
                        <Text>
                            {item?.supply == null
                                ? "--"
                                : (item?.supply as Number).abbreviate(0, 0, 2)}
                            &nbsp;
                        </Text>
                        <Text as="span" color="lavenderGray">
                            items
                        </Text>
                    </Flex>
                </>
            );
        };

        if (item?.type && item?.type == "heading") {
            return (
                <>
                    <Box style={{ ...style, top: `${parseFloat(style.top) + LIST_PADDING_Y}px` }}>
                        <Box
                            ref={itemRef}
                            py={2}
                            px={isWideLayout ? 6 : 4}
                            textStyle="site.siteText"
                        >
                            <Flex
                                position="relative"
                                direction="column"
                                w="100%"
                                justify="center"
                                align="start"
                                color="lavenderGray"
                                whiteSpace="nowrap"
                                fontSize="110%"
                            >
                                {item?.name}
                            </Flex>
                        </Box>
                    </Box>
                </>
            );
        } else if (item?.type && item?.type == "divider") {
            if (featuredList.length && standardList.length) {
                return (
                    <>
                        <Box
                            style={{ ...style, top: `${parseFloat(style.top) + LIST_PADDING_Y}px` }}
                        >
                            {/* <Box
                                ref={itemRef}
                                py={2}
                                px={isWideLayout ? 6 : 4}
                                pt={featuredList.length > 0 ? 8 : undefined}
                                textStyle="site.siteText"
                            >
                                <Flex
                                    position="relative"
                                    direction="column"
                                    w="100%"
                                    justify="center"
                                    align="start"
                                    textStyle="site.header"
                                    color="lavenderGray"
                                    whiteSpace="nowrap"
                                    fontSize="110%"
                                >
                                    Search Results
                                </Flex>
                            </Box> */}
                            <Flex
                                w="100%"
                                h="100%"
                                px={isWideLayout ? 6 : 4}
                                justify="center"
                                align="center"
                            >
                                <Box w="100%" h="1px" bgColor="lavenderGray"></Box>
                            </Flex>
                        </Box>
                    </>
                );
            } else {
                return null;
            }
        } else if (item?.type == null) {
            return (
                <NextLink href={`/c/${item?.collectionId}`}>
                    <a>
                        <Box
                            maxW="100%"
                            style={{ ...style }}
                            onMouseDown={preventDefault}
                            onClick={onItemClick}
                        >
                            <Grid
                                ref={itemRef}
                                w="100%"
                                maxW="100%"
                                py={isWideLayout ? 3 : 3}
                                px={isWideLayout ? 6 : 4}
                                bg={isActive ? "arsenic" : undefined}
                                _hover={{
                                    bg: "arsenic",
                                    cursor: isActive ? undefined : "pointer",
                                }}
                                columnGap={isWideLayout ? 4 : 2}
                                templateColumns={
                                    isWideLayout
                                        ? `auto 1fr 100px 100px 100px auto`
                                        : "auto 1fr auto auto"
                                }
                                templateRows={isWideLayout ? "auto" : "auto 1fr"}
                                templateAreas={
                                    isWideLayout
                                        ? `"image name volume floor supply extra"`
                                        : ` "image name name name" "image volume floor supply" `
                                }
                            >
                                <GridItem area="image">
                                    <Flex>
                                        <AspectRatio
                                            minW={isWideLayout ? "60px" : "50px"}
                                            ratio={1}
                                            borderRadius="5px"
                                            overflow="hidden"
                                        >
                                            <Image
                                                src={item?.imageUrl}
                                                alt={`Beyond Rarity - ${item?.name} Image`}
                                                objectFit="contain"
                                                fallback={
                                                    item?.opensea && !item?.imageurl ? (
                                                        <>
                                                            <Center
                                                                fontSize="8px"
                                                                // whitespace="normal"
                                                                bg="spaceCadetLight"
                                                                h="100%"
                                                                w="100%"
                                                                textAlign="center"
                                                                color="whiteAlpha.500"
                                                            >
                                                                <Icon
                                                                    as={MdOutlineImageNotSupported}
                                                                    fontSize="40px"
                                                                />
                                                            </Center>
                                                        </>
                                                    ) : (
                                                        <Box />
                                                    )
                                                }
                                            />
                                        </AspectRatio>
                                    </Flex>
                                </GridItem>
                                <GridItem area="name">
                                    <CollectionName />
                                </GridItem>
                                <GridItem area="volume">
                                    <Volume />
                                </GridItem>
                                <GridItem area="floor">
                                    <Floor />
                                </GridItem>
                                <GridItem area="supply">
                                    <NumberOfItems />
                                </GridItem>
                                <GridItem area="extra" display={isWideLayout ? "default" : "none"}>
                                    <Flex h="100%" justify="center" align="center">
                                        <InfoTooltip
                                            label={<ExtraInfo />}
                                            iconSize={isWideLayout ? "20px" : "16px"}
                                            iconProps={{ my: 0 }}
                                        />
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </Box>
                    </a>
                </NextLink>
            );
        }
    };

    CollectionItem.displayName = "CollectionItem";
    const MemoCollectionItem = memo(CollectionItem);

    const listHeight = isIOS ? viewportHeight * 0.65 : windowHeight * 0.65;

    return (
        <Flex
            direction="column"
            w="100%"
            position="relative"
            // zIndex="20"
            ref={searchBar}
            justify="start"
            align="center"
        >
            <ComboboxInput
                isLoading={isLoading}
                placeholder={
                    isDesktop ? "Search Collections by Name, ID or Contract" : "Search Collections"
                }
                onChange={onSearchInputChange}
                onFocus={onSearchInputFocus}
                onBlur={onSearchInputBlur}
                onClear={onSearchInputClear}
                isOpen={isOpen}
                selectedItem={selectedItem}
                listRef={listRef}
                filteredItems={filteredItems}
                fullList={fullList}
            />
            {isOpen && fullList.length > 0 && (
                <Box
                    position="absolute"
                    top="100%"
                    left="0"
                    mt={2}
                    w="100%"
                    minW={isDesktop ? "750px" : undefined}
                    rounded={20}
                    overflow="hidden"
                    zIndex={10}
                    bg="spaceCadet"
                    border="2px solid #62677A"
                >
                    {collections.length == 0 ? (
                        <LoadingItem />
                    ) : filteredItems?.length == 0 ? (
                        <NoResultsItem />
                    ) : (
                        <List
                            ref={listRef}
                            height={listHeight}
                            itemCount={fullList.length}
                            itemSize={getItemSize}
                            // width="calc(100% + 2px)"
                            style={{
                                overflowY: "auto",
                                overflowX: "hidden",
                                overscrollBehavior: "contain",
                            }}
                        >
                            {MemoCollectionItem}
                        </List>
                    )}
                </Box>
            )}
        </Flex>
    );
}
