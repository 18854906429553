import React, { memo } from "react";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import NextLink from "next/link";
import { Grid, GridItem, HStack, Image, Link } from "@chakra-ui/react";
import { CollectionDataHeader } from "@components/layout";
import CollectionSearchBar from "@components/search/CollectionSearchBar";
import QuickTourButton from "@components/quickTour/QuickTourButton";
import useCollectionStore from "@store/collectionStore";
import BannerButton from "@components/banners/BannerButton";
import { BlockchainNetworkPricesDynamic } from "@components/ui/BlockchainNetworkPricesDynamic";
import UserLogin from "@components/auth/UserLogin";
import Head from "next/head";

const GRID_LG = {
    templateColumns: "110px minmax(auto, 800px) 1fr auto auto",
    templateRows: "1fr",
    templateAreas: `
        "logo search . blockChainPrices topMenu"
        "header header header header header"
    `,
};

const GRID_MD = {
    templateColumns: "110px minmax(auto, 800px) 1fr auto",
    templateRows: "auto 35px 35px 1fr",
    templateAreas: `
        "blockChainPrices blockChainPrices blockChainPrices blockChainPrices"
        "logo search . topMenu"
        "header header header header"
    `,
};

const GRID_SM = {
    templateColumns: "90px 1fr auto",
    templateRows: "auto 35px 35px 1fr",
    templateAreas: `
        "blockChainPrices blockChainPrices blockChainPrices"
        "logo . topMenu"
        "search search search"
        "header header header"
    `,
};

const Header = (): JSX.Element => {
    const { isDesktop, windowWidth } = useDebouncedWindowSize({
        useIsDesktop: true,
        useWindowWidth: true,
    });
    const { templateColumns, templateRows, templateAreas } =
        windowWidth >= 1200 ? GRID_LG : isDesktop ? GRID_MD : GRID_SM;
    const collection = useCollectionStore((state) => state.collection);

    const MemoBlockchainNetworkPricesDynamic = memo(BlockchainNetworkPricesDynamic);

    const renderSkeleton = (): JSX.Element => <h1>SKELETON!</h1>;

    const renderHeader = (): JSX.Element => {
        return (
            <>
                <Head>
                    <title key="title">Beyond Rarity - {collection?.name}</title>
                </Head>
                <GridItem area="logo" pl={isDesktop ? "10px" : "auto"} h="35px" mt="5px">
                    <NextLink shallow href="/">
                        <Link href="/" _hover={{ textDecor: "none" }}>
                            <Image
                                src="/ui/br-monogram.svg"
                                alt="Beyond Rarity Logo"
                                w="100%"
                                h="100%"
                                _hover={{ cursor: "pointer" }}
                            />
                        </Link>
                    </NextLink>
                </GridItem>
                <GridItem area="search">
                    <CollectionSearchBar />
                </GridItem>
                <GridItem area="blockChainPrices">
                    <MemoBlockchainNetworkPricesDynamic isDesktop={isDesktop} appMode />
                </GridItem>
                <GridItem area="topMenu">
                    <HStack
                        h="100%"
                        gap={isDesktop ? "4px" : "2px"}
                        justify="center"
                        align="center"
                    >
                        <BannerButton />
                        <QuickTourButton />
                        <UserLogin />
                    </HStack>
                </GridItem>
                <GridItem area="header">
                    <CollectionDataHeader name={name} {...collection} />
                </GridItem>
            </>
        );
    };

    return (
        <Grid
            templateColumns={templateColumns}
            templateRows={templateRows}
            templateAreas={templateAreas}
            w="100%"
            columnGap={4}
            rowGap="2"
            pt={isDesktop ? undefined : "20px"}
            px={isDesktop ? undefined : "10px"}
            maxW="100vw"
        >
            {!collection ? renderSkeleton() : renderHeader()}
        </Grid>
    );
};

export default Header;
